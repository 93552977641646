import styles from "./style.module.css";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {useCallback, useState} from "react";
import {Link} from 'react-router-dom';

type ArticleCardProps = {
    title?: string;
    category?: string;
    date?: string;
    imageUrl?: string;
    imageAlt?: string;
    link?: string;
}

const ArticleCard = ({title, category, date, imageUrl, imageAlt, link}: ArticleCardProps) => {

    const [isImageLoading, setIsImageLoading] = useState(!!imageUrl);

    const handleImageLoaded = useCallback(() => {
        setIsImageLoading(false);
    }, []);

    return (
        <Link className={styles.container} to={link || "#"}>
            <div className={styles.imageContainer}>
                {isImageLoading && <Skeleton className={styles.skeleton} borderRadius={0} />}
                {
                    imageUrl && <img
                        src={imageUrl}
                        alt={imageAlt || 'thumbnail'}
                        className={styles.image}
                        loading="lazy"
                        onLoad={handleImageLoaded}
                        style={{ opacity: isImageLoading ? 0 : 1 }}
                    />
                }
                {
                    !imageUrl && <div className={styles.noImage}></div>
                }
            </div>
            <div className={styles.textContainer}>
                {
                    category && <div className={styles.categoryText}>
                        {category}
                    </div>
                }
                {
                    title && <div className={styles.titleText}  dangerouslySetInnerHTML={{ __html: title }}>

                    </div>
                }
                {
                    date && <div className={styles.dateText}>
                        {date}
                    </div>
                }
            </div>
        </Link>
    )
}

export default ArticleCard