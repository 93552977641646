import {Intl} from "./Intl.d";

let TRANSLATIONS;

if (process.env.REACT_APP_LANGUAGE === 'sk') {
    TRANSLATIONS = require('./Slovak').default;
} else {
    TRANSLATIONS = require('./Czech').default;
}

export const t = (type: keyof Intl): any => {
    return TRANSLATIONS[type];
}

