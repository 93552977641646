import React, {useMemo} from "react";
import styles from "./style.module.css";

type ItemProps = {
    imgName?: string;
    title?: string;
    text?: string;
    imgWidth?: number;
    imgHeight?: number;
}

const Item = ({title, text, imgName, imgWidth = undefined, imgHeight = undefined}: ItemProps) => {

    const imgSrc = useMemo(() =>  '/images/benefits_icons/' + imgName, [imgName]);

    return (
        <div className={styles.item}>
            <div className={styles.headingContainer}>
                <div className={styles.headingIconContainer}>
                    <img src={imgSrc} alt={"PEPTYS"} className={styles.headingIcon} width={imgWidth} height={imgHeight} />
                </div>
                <div className={styles.headingText}>
                    {title}
                </div>
            </div>
            <div className={styles.itemText}>
                {text}
            </div>
        </div>

    );
}

export default Item;