import React, {useMemo} from "react";
import styles from "./style.module.css";
import {FACEBOOK, INSTAGRAM} from "../../enviroment";


const SoMe = () => {

    const facebookSrc = useMemo(() =>  '/images/facebook.svg', []);
    const messengerSrc = useMemo(() =>  '/images/facebook-messenger.svg', []);


    return (
        <div className={styles.someContainer}>
            <a href={FACEBOOK} target={"_blank"} rel={"noreferrer"} className={styles.link}><img src={facebookSrc} alt={"Facebook"} className={styles.someIcon} width={25} height={25} fetchpriority={"low"} /></a>
            <a href={INSTAGRAM} target={"_blank"} rel={"noreferrer"} className={styles.link}><img src={messengerSrc} alt={"Messenger"}
                                                                              className={styles.someIcon} width={25} height={25} fetchpriority={"low"}/></a>
        </div>
);
}

export default SoMe;