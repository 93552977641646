import React, {useMemo} from "react";
import styles from "./style.module.css";
import Heading from "../Heading";
import {Link} from 'react-router-dom';
import {t} from "../../intl/Intl";
import {BUY_LINK, EDUCATE_LINK, LEAD_LINK} from "../../App";

const NextSteps = ({marginTop = true}) => {

    const arrowImg = useMemo(() =>  '/images/arrow-small-right.svg', []);

    return (
        <div className={`${styles.wrapper} ${marginTop ? styles.marginTop : ''}`}>
            <div className={"container"}>
                <div className={styles.container}>
                    <Heading heading={t('nextStepsHeading')} position={"left"} color={"light"} />
                    <div className={styles.row}>

                        <div className={styles.item}>
                            <div className={styles.itemTitle}>{t('nextStepsForDoctorsHeading')}</div>
                            <div className={styles.itemText}>{t('nextStepsForDoctorsLead')}</div>
                            <Link className={styles.itemArrowContainer} to={LEAD_LINK}>
                                <img src={arrowImg} alt={t('imgAltOpen')} className={styles.itemArrowImg}
                                     width={29} height={29} fetchpriority={"low"}/>
                            </Link>
                        </div>

                        <div className={styles.item}>
                            <div className={styles.itemTitle}>{t('nextStepsLearnMoreHeading')}</div>
                            <div className={styles.itemText}>{t('nextStepsLearnMoreLead')}</div>
                            <Link className={styles.itemArrowContainer} to={EDUCATE_LINK}>
                                <img src={arrowImg} alt={t('imgAltOpen')} className={styles.itemArrowImg}
                                     width={29} height={29} fetchpriority={"low"}/>
                            </Link>
                        </div>

                        <div className={styles.item}>
                            <div className={styles.itemTitle}>{t('nextStepsApplicationHeading')}</div>
                            <div className={styles.itemText}>{t('nextStepsApplicationLead')}</div>
                            <Link className={styles.itemArrowContainer} to={BUY_LINK}>
                                <img src={arrowImg} alt={t('imgAltOpen')} className={styles.itemArrowImg}
                                     width={29} height={29} fetchpriority={"low"}/>
                            </Link>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default NextSteps;