import React, {useEffect, useMemo} from "react";
import styles from "./style.module.css";
import {CardType} from "./index.d";


const Card = ({title, syndromes, imgName, products}: CardType) => {

    const imgSrc = useMemo(() =>  '/images/product_guidance/' + (imgName ?? 'image_12.png'), [imgName]);
    const [headerImgSize, setHeaderImgSize] = React.useState(50);

    // Reset menu visibility based on window width
    useEffect(() => {
        const handleResize = () => {
            const expectedWidth = window.innerWidth > 768 ? 50 : 43;
            setHeaderImgSize(expectedWidth);
            if (headerImgSize !== expectedWidth) {
                setHeaderImgSize(expectedWidth);
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [headerImgSize]);

    const mappedSyndromes = useMemo(() => {
        return syndromes?.map((syndrome, index) => (
            <div key={index}>{syndrome}<br/></div>
        ));
    }, [syndromes]);

    const mappedProducts = useMemo(() => {
        return products?.map((product, index) => (
            <a href={product.url} key={index} className={styles.productContainer} target={"_blank"} rel={"noreferrer"}>
                { product.title && <div className={styles.productTitle}>{product.title}</div> }
                { product.imgName && <img src={ '/images/products/' + product.imgName} className={styles.productImg} alt={product.title}/> }
                { product.count && <div className={styles.productCount}>{product.count}x</div> }
            </a>
        ));
    }, [products]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={imgSrc} className={styles.headerImg} alt={title} width={headerImgSize} height={headerImgSize} fetchpriority={"low"} />
                <div className={styles.headerText}>{title}</div>
            </div>
            <div className={styles.description}>
                {
                    mappedSyndromes
                }
            </div>
            <div className={styles.productsContainer}>
                {
                    mappedProducts
                }
            </div>
        </div>
    );
}

export default Card;