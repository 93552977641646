import styles from "./style.module.css";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import {BLOG_ENDPOINT} from "../../enviroment";
import {WP_REST_API_Post} from "../../types/wordpress.d";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import Header from "../../components/Header";
import NextSteps from "../../components/NextSteps";
import Footer from "../../components/Footer";
import {ReactComponent as ShareIcon} from './icons/share.svg';
import {Helmet} from "react-helmet";
import {ReactComponent as LeftArrow} from './icons/arrow-alt-left.svg';
import {t} from "../../intl/Intl";
import {BLOG_LINK} from "../../App";

const ArticlePage = () => {

    const params = useParams();
    const { slug } = useMemo(() => params, [params]);

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [article, setArticle] = useState<WP_REST_API_Post>();

    useEffect(() => {
        setIsLoading(true);
        fetch(BLOG_ENDPOINT + `/posts?slug=${slug}&_embed&meta_key=recommended_articles&meta_value=true`)
            .then(response => {
                setShowError(!response.ok);
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data) && data.length > 0 && data[0].slug === slug) {
                    setArticle(data[0]);
                } else {
                    setShowError(true);
                }
            })
            .finally(() => setIsLoading(false));
    }, [slug]);

    const thumbnailSrc = useMemo(() => {
        // @ts-ignore
        return article?._embedded['wp:featuredmedia'][0].source_url;
    }, [article]);

    const thumbnailAlt = useMemo(() => {
        // @ts-ignore
        return article?._embedded['wp:featuredmedia'][0].alt_text;
    }, [article]);

    const avatarSrc = useMemo(() => {
        // @ts-ignore
        return (article?._embedded?.author && Array.isArray(article._embedded.author) && article._embedded.author.length > 0 && article._embedded.author[0].avatar_urls) ? article._embedded.author[0].avatar_urls['96'] : undefined;
    }, [article]);

    const authorName = useMemo(() => {
        return (article?._embedded?.author && Array.isArray(article._embedded.author) && article._embedded.author.length > 0 && article._embedded.author[0].name) ? article._embedded.author[0].name : undefined;
    }, [article]);

    const publishedDate = useMemo(() => {
        return article?.date ? new Date(article?.date).toLocaleDateString('en-UK', {
            year: 'numeric', month: '2-digit', day: '2-digit'
        }) : undefined;
    }, [article]);

    const onPressShare = useCallback(() => {
        if (typeof window !== 'undefined' && navigator.share) {
            navigator.share({
                url: window.location.href,
            }).catch(() => {
            })
        }
    }, []);

    const postTitle = useMemo(() => {
        return article?.title.rendered;
    }, [article]);

    const postExcerpt = useMemo(() => {
        return article?.excerpt.rendered;
    }, [article]);

    const currentUrl = useMemo(() => {
        return typeof window !== 'undefined' ? window.location.href : undefined;
    }, []);

    return (
        <>
            <Header backgroundColor={"light"}/>

            {
                showError ? <div className={styles.errorContainer}>
                        <img src={ '/images/icons/error.svg'} alt={t('errorString')}
                             className={styles.errorImage}/>
                        <h3>{t('articleNotFoundHeading')}</h3>
                    </div> :
                    <article>
                        <div className={styles.headingContainer}>
                            <h1 className={styles.headingText}>{article?.title.rendered || (isLoading &&
                                <Skeleton/>)}</h1>
                        </div>

                        <div className={styles.thumbnailContainer}>
                            {
                                thumbnailSrc ? <img src={thumbnailSrc} alt={thumbnailAlt} className={styles.thumbnail}
                                                    loading={"lazy"}/> : (isLoading && <Skeleton height={420}/>)
                            }
                        </div>

                        <div className={styles.metaContainer}>
                            <div className={styles.avatarContainer}>
                                {
                                    isLoading ? <Skeleton circle={true} height={45} width={45}/> : <img
                                        src={avatarSrc ? avatarSrc :  '/images/avatar_placeholder.svg'}
                                        alt={authorName ? authorName : "Avatar"} className={styles.avatarImg}
                                        loading={"lazy"}/>
                                }
                            </div>
                            <div className={styles.authorContainer}>
                                <div className={styles.authorText}>
                                    {authorName || (isLoading && <Skeleton width={100}/>)}
                                </div>
                                <div className={styles.dateText}>
                                    {publishedDate || (isLoading && <Skeleton width={100}/>)}
                                </div>
                            </div>
                            <div className={styles.shareContainer}>
                                {(!isLoading && article) &&
                                    <ShareIcon role={"button"} className={styles.shareButton} onClick={onPressShare}/>}
                            </div>
                        </div>

                        {
                            !isLoading ? <div
                                className={styles.contentContainer}
                                dangerouslySetInnerHTML={article?.content.rendered ? {__html: article?.content.rendered} : undefined}
                            >
                            </div> : <div className={styles.contentContainer}>
                                <Skeleton count={5}/>
                                <Skeleton count={5}/>
                                <Skeleton count={10}/>
                                <Skeleton count={10}/>
                                <Skeleton count={5}/>
                            </div>
                        }
                    </article>
            }

            <div className={styles.backButtonContainer}>
                <Link
                    to={BLOG_LINK}
                    className={styles.backButton}
                ><LeftArrow />{t('articleBackButton')}</Link>
            </div>


            <NextSteps marginTop={true}/>
            <Footer/>

            <Helmet>
                {
                    (!isLoading && article) &&
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Article",
                                "headline": article?.title.rendered,
                                "image": thumbnailSrc,
                                "author": {
                                    "@type": "Person",
                                    "name": authorName,
                                },
                                "datePublished": publishedDate,
                                "articleBody": article?.content.rendered,
                            })}
                        </script>
                }
                { postTitle && <title>{postTitle}</title> }
                { postExcerpt && <meta name="description" content={postExcerpt}/> }
                { postTitle && <meta property="og:title" content={postTitle}/> }
                { postExcerpt && <meta property="og:description" content={postExcerpt}/> }
                { currentUrl && <meta property="og:url" content={currentUrl}/> }
                { thumbnailSrc && <meta property="og:image" content={thumbnailSrc}/> }
                { postTitle && <meta name="twitter:title" content={postTitle}/> }
                { postExcerpt && <meta name="twitter:description" content={postExcerpt}/> }
                { currentUrl && <meta name="twitter:url" content={currentUrl}/> }
                { thumbnailSrc && <meta name="twitter:image" content={thumbnailSrc}/> }
                <meta name={"og:type"} content={"article"} />
            </Helmet>
        </>
    )
}

export default ArticlePage;