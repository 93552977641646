import styles from "./style.module.css";
import React, {useMemo} from "react";


const Star = () => {

    const starSrc = useMemo(() =>  '/images/star.svg', []);

    return (<img src={starSrc} alt={"Hviezda"} className={styles.testimonialStar} width={20} height={20} fetchpriority={"low"}/>);
}

export default React.memo(Star);