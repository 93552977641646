import React from "react";
import styles from "./style.module.css";
import Item from "./Item";
import {t} from "../../intl/Intl";

const items = [
    {
        imgName: "gym.svg",
        title: t('benefit1Title'),
        text: t('benefit1Lead'),
        imgWidth: 35,
        imgHeight: 35
    },
    {
        imgName: "health.svg",
        title: t('benefit2Title'),
        text: t('benefit2Lead'),
        imgWidth: 35,
        imgHeight: 35
    },
    {
        imgName: "vitality.svg",
        title: t('benefit3Title'),
        text: t('benefit3Lead'),
        imgWidth: 35,
        imgHeight: 35
    }
]

const Benefits = () => {

    return (
        <div className={styles.wrapper}>
            <div className={"container"}>
                <div className={styles.container}>
                    {items.map((item, index) => <Item key={index} {...item} />)}
                </div>
            </div>
        </div>
    );
}

export default Benefits;