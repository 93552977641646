import React, {ReactNode, useEffect} from 'react';
import {useLocation} from "react-router-dom";

type ScrollToTopProps = {
    children: ReactNode;
};

const ScrollToTop = ({ children }: ScrollToTopProps) => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return <>{children}</>;
};

export default ScrollToTop;
