import React, {useMemo} from "react";
import styles from "./style.module.css";
import {t} from "../../intl/Intl";


const FeaturePlan = () => {

    const arrowImg = useMemo(() =>  '/images/arrows_down.svg', []);

    return (
        <>
            <div className={styles.container}>


                <div className={styles.circleContainer1}>
                    <div className={styles.circle}>
                        <img src={ '/images/icons/pain.svg'} alt={t('imgAltPain')}
                             className={`${styles.icon} ${styles.correction}`} width={32} height={45} fetchpriority={"low"}/>
                    </div>
                </div>
                <div className={styles.circleContainer2}>
                    <div className={styles.circle}>
                        <img src={ '/images/icons/time-past.svg'} alt={t('imgAltTimePast')}
                             className={styles.icon} width={32} height={32} fetchpriority={"low"}/>

                    </div>
                </div>
                <div className={styles.circleContainer3}>
                    <div className={styles.circle}>
                        <img src={ '/images/icons/syringe.svg'} alt={t('imgAltSyringe')}
                             className={styles.icon} width={32} height={32} fetchpriority={"low"} />
                    </div>
                </div>
                <div className={styles.circleContainer4}>
                    <div className={styles.circle}>
                        <img src={ '/images/icons/hand-holding-heart.svg'} alt={t('imgAltHandHoldingHeart')}
                             className={styles.icon} width={32} height={32} fetchpriority={"low"} />
                    </div>
                </div>


                <div className={styles.arrowsContainer1}>
                    <img src={arrowImg} alt={t('imgAltArrowsDown')} className={styles.arrowImg}/>
                </div>
                <div className={styles.arrowsContainer2}>
                    <img src={arrowImg} alt={t('imgAltArrowsDown')} className={styles.arrowImg}/>
                </div>
                <div className={styles.arrowsContainer3}>
                    <img src={arrowImg} alt={t('imgAltArrowsDown')} className={styles.arrowImg}/>
                </div>

                <div className={styles.textContainer1}>
                    <div className={styles.headingText}>
                        {t('featurePlanHeading1')}
                    </div>
                    <div className={styles.leadText}>
                        {t('featurePlanLead1')}
                    </div>
                </div>

                <div className={styles.textContainer2}>
                    <div className={styles.headingText}>
                        {t('featurePlanHeading2')}
                    </div>
                    <div className={styles.leadText}>
                        {t('featurePlanLead2')}
                    </div>
                </div>

                <div className={styles.textContainer3}>
                    <div className={styles.headingText}>
                        {t('featurePlanHeading3')}
                    </div>
                    <div className={styles.leadText}>
                        {t('featurePlanLead3')}
                    </div>
                </div>

            </div>

            <div className={styles.footerContainer}>
                <div className={styles.headingText}>
                    {t('featurePlanHeading4')}
                </div>
                <div className={styles.leadText}>
                    {t('featurePlanLead4')}
                </div>
            </div>
        </>
    );
}

export default FeaturePlan;