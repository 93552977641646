import Header from "../../components/Header";
import styles from "./style.module.css";
import NextSteps from "../../components/NextSteps";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import ContentStripe from "../../components/ContentStripe";
import Hero from "./components/Hero";
import ExplanationContainer from "./components/ExplanationContainer";
import MAP from "./components/MAP";
import Faq from "../../components/Faq";
import Head from "../../components/Head";
import YoutubeVideo from "../../components/YoutubeVideo";
import {useCallback} from "react";
import {RESEARCH_PAPERS_LINK} from "../../enviroment";
import {t} from "../../intl/Intl";

const QUESTIONS = t('educateFAQ');

const EducatePage = () => {

    const onClickResearchPapers = useCallback(() => {
        window.open(RESEARCH_PAPERS_LINK, "_blank");
    }, []);

    return (
        <>
            <Header backgroundColor={"light"}/>
            <Hero />
            <ExplanationContainer/>

            <MAP />

            <div className={"container"}>
                <div className={styles.container}>
                    <Heading
                        heading={t('educateVideoHeading')}
                        lead={t('educateVideoLead')}
                        position={"left"}
                    />

                    <YoutubeVideo src={"https://www.youtube.com/embed/zRSxy8hhEbw?si=dsnH_v20ygqX-dG9"} thumbnailSrc={"/images/youtube/rozhovor_peptys.webp"} />

                    <Faq questions={QUESTIONS}/>
                </div>
            </div>

            <ContentStripe
                heading={t('educateResearchPapersHeading')}
                text={t('educateResearchPapersLead')}
                buttonLabel={t('educateResearchPapersButtonLabel')}
                align={"left"}
                colorScheme={"tertiary"}
                onClick={onClickResearchPapers}
            />

            <NextSteps marginTop={false} />
            <Footer />
            <Head
                title={t('educatePageMetaTitle')}
                description={t('educatePageMetaDescription')}
            />
        </>
    )
}

export default EducatePage