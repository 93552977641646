import React, {useCallback, useState} from "react";
import styles from "./style.module.css";
import useEventTracking from "../../hooks/UseEventTracking";
import {YoutubeVideoProps} from "./index.d";

// https://www.youtube-nocookie.com/embed/pmYdX39DlXc?si=YQqRZxEP4hZSMLM5&amp;start=2

const YoutubeVideo = ({
                            src,
                            thumbnailSrc,
                            width = "560",
                            height = "315",
                            title = "YouTube video player",
                            allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                            allowFullScreen = true
                        }: YoutubeVideoProps) => {

    const logEventVideoPlay = useEventTracking('video_play', 'video');

    const [isPrefetched, setIsPrefetched] = useState(false);
    const [isIframeVisible, setIsIframeVisible] = useState(false);

    const addPrefetch = useCallback((kind: 'prefetch' | 'preconnect', url: string) => {
        const link = document.createElement("link");
        link.rel = kind;
        link.href = url;
        document.head.appendChild(link);
    }, []);

    const prefetch = useCallback(() => {
        if (isPrefetched) return;

        // The iframe document and most of its subresources come right off youtube.com
        addPrefetch('preconnect', 'https://www.youtube-nocookie.com');
        // The botguard script is fetched off from google.com
        addPrefetch('preconnect', 'https://www.google.com');

        // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
        addPrefetch('preconnect', 'https://googleads.g.doubleclick.net');
        addPrefetch('preconnect', 'https://static.doubleclick.net');

        setIsPrefetched(true);
    }, [isPrefetched, addPrefetch]);

    const switchToIframe = useCallback(() => {
        setIsIframeVisible(true);
        logEventVideoPlay();
    }, [logEventVideoPlay]);

    return (
        <div className={"ybVideoContainer"}>
            {
                isIframeVisible ?
                    <iframe width={width} height={height}
                            className={"ybVideo"}
                            src={`${src}&autoplay=1&playsinline=1`}
                            title={title}
                            frameBorder="0"
                            allow={allow}
                            allowFullScreen={allowFullScreen}></iframe> :
                    <>
                    <img src={thumbnailSrc} alt={"Video thumbnail"} className={"ybVideo"} fetchpriority={"low"} />
                        <div role={"button"} className={styles.playButton}
                             onMouseOver={prefetch} onClick={switchToIframe}></div>
                    </>
            }
        </div>
    )
}

export default YoutubeVideo;