import styles from "./style.module.css";
import {useCallback, useEffect, useRef, useState} from "react";
import {t} from "../../../../intl/Intl";


const ExplanationContainer = () => {

    const parentRef = useRef<HTMLDivElement | any>(null);
    const [maxHeight, setMaxHeight] = useState(0);

    const updateHeight = useCallback(() => {
        if (parentRef.current) {
            const children = parentRef.current.children;
            let tallest = 0;


            Array.from(children).forEach(child => {
                // @ts-ignore
                tallest = Math.max(tallest, child?.offsetHeight );
            });

            const windowWidth = window.innerWidth;
            const multiplier = windowWidth > 700 ? 4 : 3;
            setMaxHeight(tallest * multiplier);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateHeight);

        // Initial calculation
        updateHeight();

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    return (<div className={styles.wrapper} id={"explanation"}>

        <div className={styles.backgroundWrapper} style={{ height: `${maxHeight}px` }}>
            <div className={styles.whiteBackground}>

            </div>
            <div className={styles.gradientBackground}>

            </div>
        </div>

        <div className={styles.contentWrapper}>
            <div className={"container"}>

                <div className={styles.container} ref={parentRef} style={{ height: `${maxHeight}px` }}>


                    <div className={styles.contentContainer}>
                        <div className={styles.textContainer}>
                            <h2 className={styles.headingText}>{t('explanationContainer1Heading')}</h2>
                            <div className={styles.sectionHeadingText}>{t('explanationContainer1Title1')}</div>
                            <div className={styles.sectionContentText}>
                                {t('explanationContainer1Lead1')}
                            </div>

                            <div className={styles.sectionHeadingText}>{t('explanationContainer1Title2')}</div>
                            <div className={styles.sectionContentText}>
                                {t('explanationContainer1Lead2')}
                            </div>
                        </div>

                        <div className={styles.imageContainer}>
                            <img src={'/images/explanation_container/image_1.webp'}
                                 alt={t('explanationContainer1ImgAlt')}
                                 className={styles.image}
                                 width={358} height={358}
                            />
                        </div>
                    </div>


                    <div className={styles.contentContainer}>
                        <div className={styles.textContainer}>
                            <h2 className={styles.headingText}>{t('explanationContainer2Heading')}</h2>
                            <div className={styles.sectionHeadingText}>{t('explanationContainer2Title1')}</div>
                            <div className={styles.sectionContentText}>
                                {t('explanationContainer2Lead1')}
                            </div>

                            <div className={styles.sectionHeadingText}>{t('explanationContainer2Title2')}</div>
                            <div className={styles.sectionContentText}>
                                {t('explanationContainer2Lead2')}
                            </div>
                        </div>

                        <div className={styles.imageContainer}>
                            <img src={ '/images/explanation_container/image_2.webp'}
                                 alt={t('explanationContainer2ImgAlt')}
                                 className={styles.image}
                                 width={358} height={358}
                            />
                        </div>
                    </div>


                    <div className={styles.contentContainer}>
                        <div className={styles.textContainer}>
                            <h2 className={styles.headingText}>{t('explanationContainer3Heading')}</h2>
                            <div className={styles.sectionHeadingText}>{t('explanationContainer3Title1')}</div>
                            <div className={styles.sectionContentText}>
                                {t('explanationContainer3Lead1')}
                            </div>

                            <div className={styles.sectionHeadingText}>{t('explanationContainer3Title2')}</div>
                            <div className={styles.sectionContentText}>
                                {t('explanationContainer3Lead2')}
                            </div>
                        </div>

                        <div className={styles.imageContainer}>
                            <img src={ '/images/explanation_container/image_3.webp'}
                                 alt={t('explanationContainer3ImgAlt')}
                                 className={styles.image}
                                 width={358} height={358}
                            />
                        </div>
                    </div>


                </div>


            </div>

        </div>

    </div>)
}

export default ExplanationContainer