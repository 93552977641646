import {Helmet} from "react-helmet";
import React from "react";
import {
    COMPANY_ADDRESS_COUNTRY,
    COMPANY_ADDRESS_LOCALITY,
    COMPANY_ADDRESS_POSTAL_CODE,
    COMPANY_ADDRESS_STREET,
    COMPANY_LOGO,
    COMPANY_NAME,
    COMPANY_WEBSITE,
    EMAIL,
    FACEBOOK,
    INSTAGRAM,
    LANGUAGE,
    PHONE_NUMBER
} from "../../enviroment";

const CompanySchema = () => {

    return <Helmet>
        <script type="application/ld+json">
            {`
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "${COMPANY_NAME}",
                "url": "${COMPANY_WEBSITE}",
                "logo": "${COMPANY_LOGO}",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "customer service",
                    "telephone": "${PHONE_NUMBER}",
                    "email": "${EMAIL}"
                },
                "sameAs": [
                    "${INSTAGRAM}",
                    "${FACEBOOK}",
                    ${LANGUAGE === 'sk' ?? "https://peptys.sk/"},
                    ${LANGUAGE === 'cz' ?? "https://peptys.cz/"},
                    ${LANGUAGE === 'sk' ?? "https://lunomedic.sk/"},
                    ${LANGUAGE === 'cz' ?? "https://lamomedic.cz/"},
                ],
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "${COMPANY_ADDRESS_STREET}",
                    "addressLocality": "${COMPANY_ADDRESS_LOCALITY}",
                    "postalCode": "${COMPANY_ADDRESS_POSTAL_CODE}",
                    "addressCountry": "${COMPANY_ADDRESS_COUNTRY}"
                  }
            }
            `}
        </script>
    </Helmet>
}

export default React.memo(CompanySchema);