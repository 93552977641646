import React, {useCallback} from "react";
import styles from "./style.module.css";
import {useNavigate} from 'react-router-dom';
import {t} from "../../intl/Intl";
import {EDUCATE_LINK} from "../../App";


const LearnMoreStripe = () => {

    const navigate = useNavigate();

    const onClickButton = useCallback(() => {
        navigate(EDUCATE_LINK);
    }, [navigate]);

    return (
        <div className={styles.wrapper} >
            <div className={"container"}>
                <div className={styles.container}>

                    <div className={styles.columnLeft}>
                        <div className={styles.circleLeft}></div>
                        <div className={styles.headingText}>
                            {t('learnMoreStripeHeading1')}
                        </div>
                        <div className={styles.leadText}>
                            {t('learnMoreStripeLead1')}
                        </div>
                        <div className={styles.buttonContainer}>
                            <button role={"link"} onClick={onClickButton} className={styles.button}>
                                {t('learnMoreStripeButtonLabel1')}
                            </button>
                        </div>
                    </div>

                    <div className={styles.columnCenter}>
                        <div className={styles.halfCircle}></div>
                        <div className={styles.halfCircleOutline}></div>
                    </div>

                    <div className={styles.columnRight}>
                        <div className={styles.circleRight}></div>
                        <div className={styles.headingText}>
                            {t('learnMoreStripeHeading2')}
                        </div>
                        <div className={styles.leadText}>
                            {t('learnMoreStripeLead2')}
                        </div>
                        <div className={styles.buttonContainer}>
                            <button role={"link"} onClick={onClickButton} className={styles.button}>
                                {t('learnMoreStripeButtonLabel2')}
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default LearnMoreStripe;