import Header from "../../components/Header";
import styles from "./style.module.css";
import {useCallback, useEffect, useMemo, useState} from "react";
import Testimonials from "../../components/Testimonials";
import NextSteps from "../../components/NextSteps";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import BenefitsStripe from "../../components/BenefitsStripe";
import LearnMoreStripe from "../../components/LearnMoreStripe";
import ProductGuidance from "../../components/ProductGuidance";
import FeaturePlan from "../../components/FeaturePlan";
import {useNavigate} from 'react-router-dom';
import Head from "../../components/Head";
import YoutubeVideo from "../../components/YoutubeVideo";
import {Helmet} from "react-helmet";
import {t} from "../../intl/Intl";
import {BUY_LINK} from "../../App";


const Home = () => {

    const headerImg = useMemo(() =>  '/images/peptys_injekcie.webp', []);
    const [headerImgDimensions, setHeaderImgDimensions] = useState({width: 400, height: 650});

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                const expectedWidth = 400;
                const expectedHeight = 650;
                if (headerImgDimensions.width !== expectedWidth || headerImgDimensions.height !== expectedHeight) {
                    setHeaderImgDimensions({width: expectedWidth, height: expectedHeight});
                }
            } else {
                const expectedWidth = 300;
                const expectedHeight = 242;
                if (headerImgDimensions.width !== expectedWidth || headerImgDimensions.height !== expectedHeight) {
                    setHeaderImgDimensions({width: expectedWidth, height: expectedHeight});
                }
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [headerImgDimensions]);

    const navigate = useNavigate();

    const onClickButton = useCallback(() => {
        navigate(BUY_LINK);
    }, [navigate]);

    return (
        <>
            <Header backgroundColor={"primary"}/>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <div className={styles.textContainer}>
                            <h1 className={styles.title}>{t('homeHeroHeading')}</h1>
                            <span className={styles.lead}>{t('homeHeroLead')}</span>
                            <button role={"link"} onClick={onClickButton} className={styles.button}>{t('primaryActionButtonLabel')}</button>
                            <div className={styles.socialProof}>
                                {t('heroSocialProof')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <img src={headerImg} alt={"PEPTYS"} className={styles.headerImg} fetchpriority={"high"} height={headerImgDimensions.height} width={headerImgDimensions.width} />
                    </div>
                </div>
            </div>

            <BenefitsStripe/>

            <div className={"container"}>
                <div className={styles.contentContainer}>
                    <Heading
                        heading={t('homeHeading1')}
                        lead={t('homeLead1')}
                    />

                    <YoutubeVideo src={"https://www.youtube-nocookie.com/embed/pmYdX39DlXc?si=YQqRZxEP4hZSMLM5&amp;start=2"} thumbnailSrc={"images/youtube/atilla_video_thumbnail.webp"} />

                    <FeaturePlan/>

                </div>
            </div>

            <LearnMoreStripe/>

            <div className={"container"}>
                <div className={styles.contentContainer}>

                    <Heading heading={t('productGuideHeading')}
                             lead={t('productGuideLead')}/>

                    <ProductGuidance/>

                    <Heading heading={t('testimonialsHeading')}
                             lead={t('testimonialsLead')}/>
                    <Testimonials/>

                </div>
            </div>
            <NextSteps />
            <Footer />
            <Helmet>
                <link rel="preload" fetchPriority="high" as="image" href={headerImg} type="image/webp" />
            </Helmet>
            <Head
                title={t('homePageMetaTitle')}
                description={t('homePageMetaDescription')}
            />
        </>
    )
}

export default Home