import React, {useCallback, useEffect, useMemo, useState} from "react";
import styles from "./style.module.css";
import {Link} from 'react-router-dom';
import {t} from "../../intl/Intl";
import {BLOG_LINK, BUY_LINK, CONTACT_LINK, EDUCATE_LINK, LEAD_LINK} from "../../App";
import {HeaderProps} from "./index.d";
import {RESEARCH_PAPERS_LINK} from "../../enviroment";

const Header = ({backgroundColor = 'light'}: HeaderProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const showBackground = useMemo(() => isMenuOpen || isScrolled, [isMenuOpen, isScrolled]);

    const logoSrc = useMemo(() => backgroundColor === 'primary' ?  '/images/peptys_logo_white.svg' :  '/images/peptys_logo_colour.svg', [backgroundColor]);

    // transparent bg for primary header
    const headerContainer = useMemo(() => backgroundColor === 'primary' ?
        (showBackground ? styles.containerFluidPrimary : styles.containerFluid)
        : styles.containerFluidLight, [backgroundColor, showBackground]);

    const menuLink = useMemo(() => backgroundColor === 'primary' ? styles.menuLinkPrimary : styles.menuLinkLight, [backgroundColor]);
    const menuButtonMain = useMemo(() => backgroundColor === 'primary' ? styles.menuButtonMainBgPrimary : styles.menuButtonMainBgLight, [backgroundColor]);
    const menuButtonSecondary = useMemo(() => backgroundColor === 'primary' ? styles.menuButtonSecondaryBgPrimary : styles.menuButtonSecondaryBgLight, [backgroundColor]);
    const line = useMemo(() => backgroundColor === 'primary' ? styles.lineBgPrimary : styles.lineBgLight, [backgroundColor]);
    const menuMobileButton = useMemo(() => backgroundColor === 'primary' ? styles.menuMobileButtonBgPrimary : styles.menuMobileButtonBgLight, [backgroundColor]);

    const toggleMenu = useCallback(() => {
        setIsMenuOpen((s) => !s);
    }, []);

    // Reset menu visibility based on window width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1200) {
                if (isMenuOpen) {
                    setIsMenuOpen(false);
                }
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [isMenuOpen]);


    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 40) {
                if (!isScrolled) {
                    setIsScrolled(true);
                }
            } else {
                if (isScrolled) {
                    setIsScrolled(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isScrolled]);


    // do not animate bg if menu is opening or closing
    const animationStyle = useMemo(() => {
        return isMenuOpen ? styles.dropShadow : styles.animation;
    }, [isMenuOpen]);

    return (
        <header className={`${headerContainer} ${animationStyle}`}>
            <div className={"container"}>
                <div className={isMenuOpen ? styles.headerOpen : styles.headerInline}>

                    <div className={styles.row}>
                        <Link to={"/"} className={styles.logoLink}>
                            <img src={logoSrc} alt={"PEPTYS logo"} className={styles.logo} fetchpriority={"high"} height={40} width={125} />
                        </Link>
                        <div className={styles.menuMobileButtonsContainer}>
                            <Link to={BUY_LINK} className={menuMobileButton}>Kde na PEPTYS</Link>
                        </div>
                        <button onClick={toggleMenu} className={isMenuOpen ? styles.hamburgerActive : styles.hamburger} title={"Menu"} aria-label={"menu"}>
                            <span className={line}></span>
                            <span className={line}></span>
                            <span className={line}></span>
                        </button>
                    </div>

                    <div className={isMenuOpen ? styles.menuOpen : styles.menuInline}>
                        <Link to={EDUCATE_LINK} className={menuLink}>{t('linkEducate')}</Link>
                        <Link to={BLOG_LINK} className={menuLink}>{t('linkBlog')}</Link>
                        <Link to={CONTACT_LINK} className={menuLink}>{t('linkContact')}</Link>
                        <Link to={RESEARCH_PAPERS_LINK} className={menuLink}>{t('linkResearchPapers')}</Link>
                        <div className={styles.menuButtonsContainer}>
                            <Link to={LEAD_LINK} className={menuButtonSecondary}>{t('linkForDoctors')}</Link>
                            <Link to={BUY_LINK} className={menuButtonMain}>{t('linkApplication')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default React.memo(Header);