import Header from "../../components/Header";
import styles from "./style.module.css";
import NextSteps from "../../components/NextSteps";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import {useMemo} from "react";
import ContactCard, {ContactCardProps} from "./components/ContactCard";
import SoMe from "../../components/SoMe";
import {
    CID,
    COMPANY_ADDRESS_LINE_1,
    COMPANY_ADDRESS_LINE_2,
    COMPANY_NAME,
    CONTACT_MAP,
    EMAIL,
    PHONE_NUMBER,
    SALES_CONTACTS,
    TAX_ID
} from "../../enviroment";
import {Helmet} from "react-helmet";
import Head from "../../components/Head";
import {t} from "../../intl/Intl";


const ContactPage = () => {

    const mappedContactCards = useMemo(() => {
        return SALES_CONTACTS.map((contact: ContactCardProps, index) => {
            return <ContactCard key={index} {...contact} />
        });
    }, []);

    const officePhoneLink = useMemo(() => {
        return "tel:" + PHONE_NUMBER.replace(/ /g, '');
    }, []);

    const officeEmailLink = useMemo(() => {
        return "mailto:" + EMAIL;
    }, []);

    const salesSchema = useMemo(() => {
        return JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": COMPANY_NAME,
            "department": [
                {
                    "@type": "Department",
                    "name": "Sales",
                    "employee": SALES_CONTACTS.map((contact: ContactCardProps) => {
                        return {
                            "@type": "Person",
                            "telephone": contact.phone,
                            "jobTitle": t('salesRepresentativeString'),
                            "email": contact.email,
                            "areaServed": contact.location
                        }
                    })
                }
            ]
        });
    }, []);

    return (
        <>
            <Header backgroundColor={"light"}/>
            <div className={"container"}>
                <div className={styles.container}>
                    <Heading heading={t('salesRepresentativesHeading')}
                             lead={t('salesRepresentativesLead')}/>

                    <div className={styles.contactContainer}>
                        {mappedContactCards}
                    </div>

                    <Heading heading={t('contactOfficeHeading')}
                             lead={t('contactOfficeLead')}/>
                    <div className={styles.officeContainer}>
                        <div className={styles.officeContactContainer}>
                            <div className={styles.officeBlockContainer}>
                                <div className={styles.titleText}>{t('contactString')}</div>
                                <div className={styles.detailsText}>
                                    {t('phoneString')}: <a href={officePhoneLink}
                                                className={styles.colorSecondary}>{PHONE_NUMBER}</a><br/>
                                    {t('emailString')}: <a href={officeEmailLink} className={styles.colorSecondary}>{EMAIL}</a>
                                </div>
                            </div>
                            <div className={styles.officeBlockContainer}>
                                <div className={styles.titleText}>{t('addressString')}</div>
                                <div className={styles.detailsText}>
                                    {COMPANY_NAME}<br/>
                                    {COMPANY_ADDRESS_LINE_1}<br/>
                                    {COMPANY_ADDRESS_LINE_2}
                                </div>
                            </div>
                            <div className={styles.officeBlockContainer}>
                                <div className={styles.titleText}>{t('companyString')}</div>
                                <div className={styles.detailsText}>
                                    IČ: {CID}<br/>
                                    DIČ: {TAX_ID}<br/>
                                </div>
                            </div>
                            <div className={styles.someContainer}>
                                <SoMe/>
                            </div>
                        </div>
                        <div className={styles.mapContainer}>
                            <iframe
                                title={t('mapString')}
                                width="100%"
                                height="450"
                                loading="lazy"
                                allowFullScreen
                                className={styles.map}
                                referrerPolicy="no-referrer-when-downgrade"
                                src={CONTACT_MAP}>
                            </iframe>
                        </div>
                    </div>

                </div>
            </div>
            <NextSteps marginTop={true}/>
            <Footer/>
            <Helmet>
                <script type="application/ld+json">{salesSchema}</script>
            </Helmet>
            <Head
                title={t('contactPageMetaTitle')}
                description={t('contactPageMetaDescription')}
            />
        </>
    )
}

export default ContactPage