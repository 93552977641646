import React, {useMemo} from "react";
import styles from "./style.module.css";
import {HeadingType} from "./index.d";

const Heading = ({heading, lead, position = 'center', color = 'dark'}: HeadingType) => {

    const positionClass = useMemo((): string => {
        if (position === 'center') {
            return styles.containerPositionCenter;
        } else if (position === 'left') {
            return styles.containerPositionLeft;
        } else {
            return '';
        }
    }, [position]);

    const colorClass = useMemo((): string => {
        if (color === 'dark') {
            return styles.containerColorDark;
        } else if (color === 'light') {
            return styles.containerColorLight;
        } else {
            return '';
        }
    }, [color]);


    return (
        <div className={`${styles.container} ${positionClass} ${colorClass}`}>
            <h2 className={styles.heading}>{heading}</h2>
            {lead && <div className={styles.lead}>{lead}</div>}
        </div>
    );
}

export default Heading;