import Header from "../../components/Header";
import styles from "./style.module.css";
import NextSteps from "../../components/NextSteps";
import Footer from "../../components/Footer";
import Head from "../../components/Head";
import {ReactComponent as LeftArrow} from './icons/arrow-alt-left.svg';
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {t} from "../../intl/Intl";


const NotFound = () => {

    const navigate = useNavigate();

    const [showBackButton, setShowBackButton] = useState(false);

    useEffect(() => {
        const handleHistoryChange = () => {
            setShowBackButton(window.history.length > 1);
        };

        window.addEventListener('popstate', handleHistoryChange);
        handleHistoryChange(); // Check the initial history length

        return () => {
            window.removeEventListener('popstate', handleHistoryChange);
        };
    }, []);

    const onPressBackButton = useCallback(() => {
        window.history.back();
    }, []);

    const onPressHomeButton = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <>
            <Header />

            <div className={"container"}>
                <div className={styles.contentContainer}>

                    <div className={styles.textBg}>{t('notFoundPageError')}</div>
                    <div className={styles.headingText}>{t('notFoundPageHeading')}</div>
                    <div className={styles.leadText}>{t('notFoundPageLead')}</div>

                    <div className={styles.buttonsContainer}>
                        {showBackButton && <button role="link" className={styles.buttonLight} onClick={onPressBackButton}><LeftArrow/> {t('notFoundPageBackButtonLabel')}
                        </button> }
                        <button role="link" className={styles.button} onClick={onPressHomeButton}>{t('notFoundPageHomeButtonLabel')}</button>

                    </div>

                </div>
            </div>

            <NextSteps/>
            <Footer/>
            <Head
                title={t('notFoundPageMetaTitle')}
            />
        </>
    )
}

export default NotFound;