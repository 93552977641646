import React from "react";
import styles from "./style.module.css";
import Card from "./components/Card";
import {ProductDetailsType} from "./components/Card/index.d";
import {t} from "../../intl/Intl";
import {PD_PEPTYS_21, PD_PEPTYS_22, PD_PEPTYS_51, PD_PEPTYS_52, PD_PEPTYS_REGAIN} from "../../enviroment";

const PEPTYS_21: ProductDetailsType = {
    imgName: "peptys21_thumbnail.webp",
    title: "PEPTYS 21/1ml",
    url: PD_PEPTYS_21.link
}

const PEPTYS_22: ProductDetailsType = {
    imgName: "peptys22_thumbnail.webp",
    title: "PEPTYS 22/2ml",
    url: PD_PEPTYS_22.link
}

const PEPTYS_51: ProductDetailsType = {
    imgName: "peptys51_thumbnail.webp",
    title: "PEPTYS 51/1ml",
    url: PD_PEPTYS_51.link
}

const PEPTYS_52: ProductDetailsType = {
    imgName: "peptys52_thumbnail.webp",
    title: "PEPTYS 52/2ml",
    url: PD_PEPTYS_52.link
}

const PEPTYS_110: ProductDetailsType = {
    imgName: "peptys110_thumbnail.webp",
    title: "PEPTYS Regain",
    url: PD_PEPTYS_REGAIN.link
}

const ProductGuidance = () => {

    return (
        <div className={styles.container}>
            <div className={styles.card1}>

                <Card
                    imgName={"image_14.webp"}
                    title={t('productGuidanceTitle1')}
                    syndromes={t('productGuidanceSyndromes1')}
                    products={[{...PEPTYS_52, count: 1}, {...PEPTYS_22, count: 2}]}
                />

            </div>
            <div className={styles.card2}>

                <Card
                    imgName={"image_15.webp"}
                    title={t('productGuidanceTitle2')}
                    syndromes={t('productGuidanceSyndromes2')}
                    products={[{...PEPTYS_52, count: 1}, {...PEPTYS_22, count: 1}]}
                />

            </div>
            <div className={styles.card3}>

                <Card
                    imgName={"image_13.webp"}
                    title={t('productGuidanceTitle3')}
                    syndromes={t('productGuidanceSyndromes3')}
                    products={[{...PEPTYS_110, count: 1}]}
                />

            </div>
            <div className={styles.card4}>

                <Card
                    imgName={"image_16.webp"}
                    title={t('productGuidanceTitle4')}
                    syndromes={t('productGuidanceSyndromes4')}
                    products={[{...PEPTYS_21, count: 2}, {...PEPTYS_51, count: 1}]}
                />

            </div>
            <div className={styles.card5}>

                <Card
                    imgName={"image_12.webp"}
                    title={t('productGuidanceTitle5')}
                    syndromes={t('productGuidanceSyndromes5')}
                    products={[{...PEPTYS_21, count: 2}, {...PEPTYS_22, count: 1}]}
                />

            </div>
        </div>
    );
}

export default React.memo(ProductGuidance);