import styles from "./style.module.css";
import Header from "../../components/Header";
import NextSteps from "../../components/NextSteps";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import ArticleCard from "./components/ArticleCard";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useSearchParams} from 'react-router-dom';
import {BLOG_ENDPOINT} from "../../enviroment";
import {WP_REST_API_Posts} from "../../types/wordpress";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {ReactComponent as AngleLeft} from './icons/angle-small-left.svg';
import {ReactComponent as AngleDoubleLeft} from './icons/angle-double-small-left.svg';
import {ReactComponent as AngleRight} from './icons/angle-small-right.svg';
import {ReactComponent as AngleDoubleRight} from './icons/angle-double-small-right.svg';
import Head from "../../components/Head";
import {t} from "../../intl/Intl";
import {BLOG_LINK} from "../../App";


const PER_PAGE = 9;

const BlogPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    // const navigate = useNavigate();

    const [posts, setPosts] = useState<WP_REST_API_Posts>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [showError, setShowError] = useState(false);

    const page = useMemo(() => {
        const n = parseInt(searchParams.get('page') || "1", 10)
        return n > 0 ? n : 1;
    }, [searchParams]);

    const articles = useMemo(() => {
        return posts.map((post, index) => {
            const featuredMedia = post._embedded ? post._embedded['wp:featuredmedia'] : [];
            const featuredMedium = featuredMedia && featuredMedia.length > 0 ? featuredMedia[0] : undefined;

            const title = post.title.rendered;

            // @ts-ignore
            const imageUrl = featuredMedium?.source_url;
            // @ts-ignore
            const imageAlt = featuredMedium?.alt_text;
            // @ts-ignore
            const category = post._embedded['wp:term'][0][0].name;
            const date = new Date(post.date).toLocaleDateString('en-UK', {
            year: 'numeric', month: '2-digit', day: '2-digit'
        });

            const link =  `${BLOG_LINK}/${post.slug}`;

            return (
                <ArticleCard
                    key={index}
                    {...{title, imageUrl, imageAlt, category, date, link}}
                />
            )
        });
    }, [posts]);


    const pagesToRender = useMemo(() => {
        const to = Math.min(totalPages, page + 2);
        const from = to <= 5 ? 1 : Math.max(1, page - 2);
        const pages = [];
        for (let i = from; i <= to; i++) {
            pages.push(i);
        }
        return pages;
    }, [page, totalPages]);

    useEffect(() => {
        window.scrollTo({top: 0});
        setIsLoading(true);

        fetch(BLOG_ENDPOINT + `/posts?_embed&per_page=${PER_PAGE}&page=${page}`)
            .then(response => {
                if (!response.ok) {
                    setShowError(true);
                } else {
                    setTotalPages(Number(response.headers.get('X-WP-TotalPages')));
                    setShowError(false);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (Array.isArray(data)) {
                    setPosts(data);
                }
            })
            .finally(() => setIsLoading(false));
    }, [page]);

    const goToPage = useCallback((p: number | string) => {
        // navigate(`/blog?page=${p}`, { replace: true });
        setSearchParams({page: p.toString()});
    }, [setSearchParams]);

    const goToNextPage = useCallback(() => {
        const nextPage = Math.min(page + 1, totalPages);
        goToPage(nextPage);
    }, [page, goToPage, totalPages]);

    const goToPreviousPage = useCallback(() => {
        const prevPage = Math.max(1, page - 1);
        goToPage(prevPage);
    }, [page, goToPage]);

    const goToFirstPage = useCallback(() => {
        goToPage(1);
    }, [goToPage]);

    const goToLastPage = useCallback(() => {
        goToPage(totalPages);
    }, [goToPage, totalPages]);

    const showPagination = useMemo(() => totalPages > 1, [totalPages]);
    const showPrevious = useMemo(() => page > 1, [page]);
    const showNext = useMemo(() => page < totalPages, [page, totalPages]);
    const showFirst = useMemo(() => page > 3, [page]);
    const showLast = useMemo(() => page < totalPages - 2, [page, totalPages]);

    return (
        <>
            <Header backgroundColor={"light"}/>

            <div className={"container"}>
                <div className={styles.container}>
                    <Heading
                        heading={t('blogHeading')}
                        position={"center"}
                    />

                    { showError && <div className={styles.errorContainer}>
                        <img src={ '/images/icons/error.svg'} alt={t('imgAltError')} className={styles.errorImage} />
                        <h3>{t('blogEmptyMessage')}</h3>
                    </div> }

                    <div className={styles.articleCardsContainer}>
                        {isLoading && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => {
                            return (
                                <Skeleton key={index} height={430} className={styles.skeleton} borderRadius={"1.75rem"} />
                            )
                        })}
                        {!isLoading && articles}
                    </div>

                    {showPagination && <div className={styles.paginationContainer}>
                        <AngleDoubleLeft
                            role={"button"}
                            className={`${styles.paginationButton} ${showFirst ? '' : styles.paginationDisabled}`}
                            onClick={goToFirstPage}
                            />
                            <AngleLeft
                                role={"button"}
                                className={`${styles.paginationButton} ${showPrevious ? '' : styles.paginationDisabled}`}
                                onClick={goToPreviousPage}
                            />

                        {
                            pagesToRender.map((pageNumber) => {
                                return (
                                    <div
                                        role={"link"}
                                        key={"page" + pageNumber}
                                        className={`${styles.paginationButton} ${page === pageNumber ? styles.paginationActive : ''}`}
                                        onClick={() => goToPage(pageNumber)}
                                    >
                                        {pageNumber}
                                    </div>
                                )
                            })
                        }
                        <AngleRight
                            role={"link"}
                            className={`${styles.paginationButton} ${showNext ? '' : styles.paginationDisabled}`}
                            onClick={goToNextPage}
                            />
                        <AngleDoubleRight
                            role={"link"}
                            className={`${styles.paginationButton} ${showLast ? '' : styles.paginationDisabled}`}
                            onClick={goToLastPage}
                            />
                    </div>}

                </div>
            </div>


            <NextSteps marginTop={true}/>
            <Footer/>
            <Head
                title={t('blogMetaTitle')}
                description={t('blogMetaDescription')}
            />

        </>
    )
}



export default BlogPage