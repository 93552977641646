import styles from "./style.module.css";
import {ReactComponent as ArrowDown} from './icons/arrow-small-down.svg';
import React, {useCallback} from "react";
import {t} from "../../../../intl/Intl";

const Hero = () => {

    const scrollToExplanation = useCallback(() => {
        const element = document.getElementById("explanation");
        element?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (<>

        <div className={styles.wrapper}>
            <div className={"container"}>
                <div className={styles.container}>
                    <div className={styles.headingContainer}>
                        <h1 className={styles.headingText}>{t('educatePageHeroHeading')}</h1>
                    </div>
                    <div className={styles.leadContainer}>
                        <div className={styles.leadText}>
                            {t('educatePageHeroLead')}
                        </div>
                        <button onClick={scrollToExplanation} className={styles.button}>
                            {t('educatePagePrimaryActionButtonLabel')} <ArrowDown className={styles.icon} />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className={styles.wrapperBottomRow}>
            <div className={"container"}>
                <div className={styles.containerBottomRow}>
                    <div className={styles.imgContainer}>
                        <img src={ '/images/products/peptys21-800x800.webp'} alt={"PEPTYS 21"}
                             className={styles.img} width={243} height={150} />
                    </div>
                    <div className={styles.imgContainer}>
                        <img src={ '/images/products/peptys51-800x800.webp'} alt={"PEPTYS 51"}
                             className={styles.img} width={243} height={149} />
                    </div>
                    <div className={styles.imgContainer}>
                        <img src={ '/images/products/peptys110-800x800.webp'} alt={"PEPTYS 110"}
                             className={styles.img} width={243} height={173} />
                    </div>
                    <div className={styles.imgContainer}>
                        <img src={ '/images/products/peptys21-800x800.webp'} alt={"PEPTYS 21"}
                             className={styles.img} width={243} height={150} />
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default Hero;