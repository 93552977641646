import React, {useMemo} from "react";
import styles from "./style.module.css";
import {ContentStripeProps} from "./index.d";

const ContentStripe = ({heading, text, buttonLabel, onClick, align = "center", colorScheme = "tertiary"}: ContentStripeProps) => {

    const colorSchemeClass = useMemo(() => {
        if (colorScheme === "primary") {
            return styles.colorSchemePrimary;
        } else if (colorScheme === "secondary") {
            return styles.colorSchemeSecondary;
        } else if (colorScheme === "tertiary") {
            return styles.colorSchemeTertiary;
        } else if (colorScheme === "light") {
            return styles.colorSchemeLight;
        } else if (colorScheme === "dark") {
            return styles.colorSchemeDark;
        } else {
            return styles.colorSchemeLight;
        }
    }, [colorScheme]);

    return (
        <div className={`${styles.wrapper} ${colorSchemeClass}`}>
            <div className={"container"}>
                <div className={`${styles.container} ${align === "left" ? styles.containerLeft : styles.containerCenter}`}>
                    { heading && <h2 className={styles.heading}>{heading}</h2> }
                    { text && <div className={styles.text}>{text}</div> }
                    { buttonLabel && <button role={"link"} className={styles.button} onClick={onClick}>{buttonLabel}</button> }
                </div>
            </div>
        </div>
    );
}

export default ContentStripe;