import styles from "./style.module.css";
import React, {useCallback, useMemo, useState} from "react";
import Spinner from "../../components/Spinner";
import {Link} from 'react-router-dom';
import {ReactComponent as LeftArrow} from './icons/arrow-alt-left.svg';
import CompanySchema from "../../components/CompanySchema";
import Head from "../../components/Head";
import {API_URL, PRIVACY_POLICY_LINK} from "../../enviroment";
import useEventTracking from "../../hooks/UseEventTracking";
import {t} from "../../intl/Intl";

const LeadPage = () => {

    const [isPending, setIsPending] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        clinic_name: '',
        city: '',
        subscribe: false,
    });
    const [validationErrors, setValidationErrors] = useState<any>({});

    const logoSrc = useMemo(() =>  '/images/peptys_logo_colour.svg', []);

    const [showSuccess, setShowSuccess] = useState(false);

    const logEventFormStart = useEventTracking('form_start', 'lead');
    const logEventFormSubmit = useEventTracking('form_submit', 'lead');

    const handleChange = useCallback((event: any) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
        setValidationErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: ''
        }));
        logEventFormStart();
    }, [logEventFormStart]);

    const onSubmit = useCallback(async (event: any) => {
        event.preventDefault();
        setIsPending(true);

        try {
            const response = await fetch(`${API_URL}/api/lead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            console.log('response', response);

            if (!response.ok) {
                const errorData = await response.json();
                setValidationErrors(errorData.errors);
            } else {
                setShowSuccess(true);
            }
            logEventFormSubmit();
        } catch (error) {
            console.error('Error submitting form:', error);
        }

        setIsPending(false);
    }, [formData, logEventFormSubmit]);



    return (
        <>
            <div className={styles.circleContainer}>
            <div className={styles.circle}></div>
            </div>

    <div className={"container"}>
        <div className={styles.flexColumn}>

            <header className={styles.header}>
                <img src={logoSrc} alt={"PEPTYS logo"} className={styles.logo} height={40} width={125} fetchpriority={"high"}/>
            </header>

            <div className={styles.container}>
                <div className={styles.textColumn}>
                    <div className={styles.headingContainer}>
                        <div className={styles.leadText}>
                            {t('leadPageLeadText')}
                        </div>
                        <div className={styles.headingText}>
                            {t('leadPageHeadingText')}
                        </div>
                    </div>
                    <div className={styles.stepsTitleText}>
                        {t('leadPageStepsTitle')}
                    </div>
                    <div className={styles.stepsContainer}>
                        <div className={styles.stepContainer}>
                            <div className={styles.stepNumber}>
                                1
                            </div>
                            <div className={styles.stepText}>
                                {t('leadPageStep1')}
                            </div>
                        </div>

                        <div className={styles.stepContainer}>
                            <div className={styles.stepNumber}>
                                2
                            </div>
                            <div className={styles.stepText}>
                                {t('leadPageStep2')}
                            </div>
                        </div>

                        <div className={styles.stepContainer}>
                            <div className={styles.stepNumber}>
                                3
                            </div>
                            <div className={styles.stepText}>
                                {t('leadPageStep3')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.column}>
                    {
                        showSuccess ? (
                            <div className={styles.successContainer} >

                                <div className={styles.successCheckmark}>
                                    <div className={styles.checkIcon}>
                                        <span className={`${styles.iconLine} ${styles.lineTip}`}></span>
                                        <span className={`${styles.iconLine} ${styles.lineLong}`}></span>
                                        <div className={styles.iconCircle}></div>
                                        <div className={styles.iconFix}></div>
                                    </div>
                                </div>

                                <div className={styles.successText}>
                                    {t('leadPageThankYouString1')} <br/> {t('leadPageThankYouString2')}
                                </div>

                                <Link to={"/"} className={styles.successButtonContainer}>
                                    <LeftArrow/> {t('leadPageBackButtonLabel')}
                                </Link>
                            </div>
                        ) : (
                            <form className={styles.leadCardContainer} onSubmit={onSubmit}
                                  >
                                <div className={styles.inputContainer}>
                                <input
                                type={"text"}
                                name="name"
                                placeholder={t('leadPageFormName') + "*"}
                                autoComplete="name"
                                className={`${styles.inputText} ${validationErrors.name ? styles.invalid : ''}`}
                                disabled={isPending}
                                value={formData.name}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                type={"email"}
                                name="email"
                                placeholder={t('leadPageFormEmail') + "*"}
                                autoComplete="email"
                                className={`${styles.inputText} ${validationErrors.email ? styles.invalid : ''}`}
                                disabled={isPending}
                                value={formData.email}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                type={"tel"}
                                name={"phone_number"}
                                placeholder={t('leadPageFormPhone') + "*"}
                                autoComplete="tel"
                                className={`${styles.inputText} ${validationErrors.phone_number ? styles.invalid : ''}`}
                                disabled={isPending}
                                value={formData.phone_number}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                type={"text"}
                                name={"clinic_name"}
                                placeholder={t('leadPageFormClinicName')}
                                autoComplete="organization"
                                className={`${styles.inputText} ${validationErrors.clinic_name ? styles.invalid : ''}`}
                                disabled={isPending}
                                value={formData.clinic_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                type={"text"}
                                name={"city"}
                                placeholder={t('leadPageFormCity')}
                                autoComplete="address-level2"
                                className={`${styles.inputText} ${validationErrors.city ? styles.invalid : ''}`}
                                disabled={isPending}
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.termsText}>
                            {t('leadPageFormTerms1')} <a href={PRIVACY_POLICY_LINK} className={styles.link} target={"_blank"} rel="noreferrer">{t('leadPageFormTerms2')}</a>.
                        </div>
                        <div className={styles.newsletterContainer}>
                            <input
                                type={"checkbox"}
                                id="subscribe"
                                name="subscribe"
                                className={styles.checkbox}
                                disabled={isPending}
                                checked={formData.subscribe}
                                onChange={handleChange}
                            />
                            <label htmlFor="subscribe" className={styles.newsletterText}>
                                {t('leadPageFormSubscribe')}
                            </label>
                        </div>
                        <div className={styles.buttonContainer}>
                            <button type={"submit"} className={styles.button} disabled={isPending}>{ isPending ? <Spinner /> : t('leadPageFormButtonLabel') }</button>
                        </div>
                    </form>
                            )}
                </div>
            </div>

            <div className={styles.footerContainer}>
                <div className={styles.footerText}>
                    {t('leadPageSocialProof')}
                </div>
            </div>

        </div>

        </div>
            <CompanySchema />
            <Head
                title={t('leadPageMetaTitle')}
                description={t('leadPageMetaDescription')}
            />
        </>
    )
}

export default LeadPage