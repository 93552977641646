import {Helmet} from "react-helmet";
import React, {useMemo} from "react";
import {useLocation} from 'react-router-dom';
import {LANGUAGE} from "../../enviroment";
import {HeadProps} from "./index.d";

const Head = ({title, description, image, type = "website"}: HeadProps) => {

    const location = useLocation();
    const currentUrl = useMemo(() => `https://peptys.${LANGUAGE}${location.pathname}`, [location.pathname]);

    const ogImage = useMemo(() => {
        return image ? image :  '/og-image.png';
    }, [image])

    return <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
        { title && <meta property="og:title" content={title + " - PEPTYS"}/> }
        { description && <meta property="og:description" content={description}/> }
        <meta property="og:image" content={ogImage}/>
        <meta property="og:type" content={type}/>
        <meta property={"og:url"} content={currentUrl}/>

    </Helmet>
}

export default Head;