import styles from "./style.module.css";
import {t} from "../../../../intl/Intl";


const MAP = () => {

    return (
        <div className={styles.wrapper}>
            <div className={"container"}>
                <div className={styles.container}>

                    <div className={styles.headingContainer}>
                        <h1 className={styles.headingText}>{t('explanationMAPHeading')}<br/>
                            <i>{t('explanationMAPString')}</i></h1>
                    </div>

                    <div className={styles.contentContainer}>
                        <div className={styles.contentHeadingText}>
                            {t('explanationMAPTitle1')}
                        </div>
                        <div className={styles.contentText}>
                            {t('explanationMAPLead1')}
                        </div>
                    </div>

                    <div className={styles.contentContainer}>
                        <div className={styles.contentHeadingText}>
                            {t('explanationMAPTitle2')}
                        </div>
                        <div className={styles.contentText}>
                            {t('explanationMAPLead2')}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MAP;