import {ContactCardProps} from "./routes/ContactPage/components/ContactCard";

export const LANGUAGE: 'sk' | 'cz' = (process.env.REACT_APP_LANGUAGE as 'sk' | 'cz') || 'sk';


export const BLOG_ENDPOINT = LANGUAGE === 'sk' ? "https://blog.peptys.sk/wp-json/wp/v2" : "https://blog.peptys.cz/wp-json/wp/v2";
export const INSTAGRAM = "https://www.instagram.com/lunomedic_sk/";
export const FACEBOOK = "https://www.facebook.com/lunomedic/";
export const API_URL = "https://api.peptys.sk";


export const PHONE_NUMBER = LANGUAGE === 'sk' ? "+421 948 553 989" : '+420 724 965 164';
export const EMAIL = LANGUAGE === 'sk' ? "office@lunomedic.sk" : 'info@lamomedic.cz';
export const COMPANY_NAME = LANGUAGE === 'sk' ? "lunomedic, s.r.o." : 'LAMOMEDIC, s.r.o.';
export const COMPANY_WEBSITE = LANGUAGE === 'sk' ? "https://lunomedic.sk" : 'https://lamomedic.cz';
export const COMPANY_LOGO = "https://lunomedic.sk/wp-content/uploads/logo-lunomedic_136.png";
export const COMPANY_ADDRESS_STREET = LANGUAGE === 'sk' ? "Horná 61A" : 'Radlická 2343/48';
export const COMPANY_ADDRESS_LOCALITY = LANGUAGE === 'sk' ? "Banská Bystrica" : 'Praha 5 – Smíchov';
export const COMPANY_ADDRESS_POSTAL_CODE = LANGUAGE === 'sk' ? "974 01" : '150 00';
export const COMPANY_ADDRESS_COUNTRY = LANGUAGE === 'sk' ? "SK" : 'CZ'

export const COMPANY_ADDRESS_LINE_1 = COMPANY_ADDRESS_STREET;
export const COMPANY_ADDRESS_LINE_2 = `${COMPANY_ADDRESS_POSTAL_CODE} ${COMPANY_ADDRESS_LOCALITY}`;

export const SALES_CONTACTS: ContactCardProps[] = LANGUAGE === 'sk' ? [
    {
        location: "Trnava, Piešťany, Hlohovec",
        name: "Mgr. Peter Grus",
        phone: "+421 948 621 539",
        email: "p.grus@lunomedic.sk",
    },
    {
        location: "Západné Slovensko",
        name: "Mgr. Lucia Chovanová",
        phone: "+421 948 008 840",
        email: "l.chovanova@lunomedic.sk"
    },
    {
        location: "Stredné Slovensko – Sever",
        name: "Mgr. Miroslava Hromcová",
        phone: "+421 948 938 197",
        email: "m.hromcova@lunomedic.sk",
    },
    {
        location: "Stredné Slovensko – Juh",
        name: "Mgr. Marián Babáč",
        phone: "+421 948 088 440",
        email: "m.babac@lunomedic.sk",
    },
    {
        location: "Východné Slovensko",
        name: "PaedDr. Katarína Hagovská",
        phone: "+421 948 276 883",
        email: "k.hagovska@lunomedic.sk",
    },
    {
        location: "Východné Slovensko",
        name: "Mgr. Miloš Potočný",
        phone: "+421 908 777 390",
        email: "potocny@lunomedic.sk",
    },
] : [
    {
        location: "Česká republika",
        name: "Gabriela Exner",
        position: "Product specialist",
        phone: "+420 608 877 731",
        email: "gabriela.exner@lamomedic.cz",
        specialization: "Ortopedie, traumatologie a rehabilitační",
    },
    {
        location: "Česká republika",
        name: "Marie Chládková",
        position: "Sales Manager",
        phone: "+420 702 151 448",
        email: "m.chladkova@lamomedic.cz",
        specialization: "Ortopedie, traumatologie a rehabilitační",
    },
        {
            location: "Česká republika",
            name: "Petr Maláč",
            position: "Sales Manager",
            phone: "+420 724 965 164",
            email: "malac@lamomedic.cz",
            specialization: "Algeziologie a neurologie"
        },
        {
            location: "Česká republika",
            name: "Mgr. Peter Grus",
            position: "Business Development Manager",
            phone: "+421 948 621 539",
            email: "grus@lamomedic.cz",
            specialization: "Algeziologie a neurologie",
        }
    ]


export const TERMS_LINK = LANGUAGE === 'sk' ? "https://lunomedic.sk/obchodne-podmienky/" : 'https://www.lamomedic.cz/obchodni-podminky/';
export const PRIVACY_POLICY_LINK = LANGUAGE === 'sk' ? "https://lunomedic.sk/ochrana-sukromia/" : 'https://www.lamomedic.cz/ochrana-soukromi/';

export const RESEARCH_PAPERS_LINK = "https://local-sodalite-55c.notion.site/Klinick-t-die-a0bfe155ba0d4a67b23773a13c4c1dec";
export const PACKAGE_LEAFLET_LINK = "https://lunomedic.sk/wp-content/uploads/Peptys_IFU-rev03-SK-CZ.pdf";

export const CID = LANGUAGE === 'sk' ? '51168511' : '19883170';
export const TAX_ID = LANGUAGE === 'sk' ? '2120619215' : 'CZ19883170';


export const CONTACT_MAP = LANGUAGE === 'sk' ?
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1129.5476518308499!2d19.152274772445438!3d48.739020918132766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47155b271fb42a73%3A0x13132cb4484fab4b!2sLunomedic.sk%20-%20Dovozca%20inovat%C3%ADvnych%20zdravotn%C3%ADckych%20pom%C3%B4cok!5e0!3m2!1ssk!2ssk!4v1702987680697!5m2!1ssk!2ssk"
    : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.0538364744466!2d14.402577!3d50.0665534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b9450c14a5d95%3A0x4c8e96e63d0d0ba2!2zUmFkbGlja8OhIDIzNDMvNDgsIDE1MCAwMCBQcmFoYSA1LVNtw61jaG92LCDEjGVza28!5e0!3m2!1ssk!2ssk!4v1710505022247!5m2!1ssk!2ssk";
export const GA_TRACKING_ID = LANGUAGE === 'sk' ? 'G-HEQ1ENMJQE' : 'G-MBLCYKBZEJ';
export const META_PIXEL = "1599552280626159"


export const PD_PEPTYS_21 = LANGUAGE === 'sk' ?
    {
        link: 'https://lunomedic.sk/obchod/peptys-21-1ml/',
        price: 95.00
    } :
    {
        link: 'https://lamomedic.cz/obchod/peptys-21-1ml/',
        price: 2530.00
    };

export const PD_PEPTYS_22 = LANGUAGE === 'sk' ?
    {
        link: 'https://lunomedic.sk/obchod/peptys-22-2ml/',
        price: 135.00
    } :
    {
        link: 'https://lamomedic.cz/obchod/peptys-22-2ml/',
        price: 3450.00
    };


export const PD_PEPTYS_51 = LANGUAGE === 'sk' ?
    {
        link: 'https://lunomedic.sk/obchod/peptys-51-1ml/',
        price: 146.00
    } :
    {
        link: 'https://lamomedic.cz/obchod/peptys-51-1ml/',
        price: 3570.00
    };


export const PD_PEPTYS_52 = LANGUAGE === 'sk' ?
    {
        link: 'https://lunomedic.sk/obchod/peptys-52-2ml/',
        price: 245.00
    } :
    {
        link: 'https://lamomedic.cz/obchod/peptys-52-2ml/',
        price: 5990.00
    };

export const PD_PEPTYS_REGAIN = LANGUAGE === 'sk' ?
    {
        link: 'https://lunomedic.sk/obchod/peptys-110-10ml-regain/',
        price: 252.00
    } :
    {
        link: 'https://lamomedic.cz/obchod/peptys-110-regain-10ml/',
        price: 6190.00
    };