import styles from "./style.module.css";
import React, {useMemo} from "react";
import {t} from "../../../../intl/Intl";

type ProductType = {
    imgSrc: string;
    imgWidth?: number;
    imgHeight?: number;
    title: string;
    price: number;
    link: string;
}

const Product = ({imgSrc, title, price, link, imgWidth, imgHeight}: ProductType) => {

    const arrowImg = useMemo(() =>  '/images/arrow-small-right.svg', []);

    return (
        <div className={styles.container}>
            <div className={styles.imgContainer}>
                <img src={imgSrc} alt={title} className={styles.img} fetchpriority={"low"}
                     width={imgWidth} height={imgHeight}/>
            </div>
            <div className={styles.metaContainer}>
                <div className={styles.descriptionContainer}>
                    <div className={styles.titleText}>{title}</div>
                    <div className={styles.priceText}>{price}{t('currencySymbol')}</div>
                </div>
                <div className={styles.buttonContainer}>

                    <a href={link} target={"_blank"} rel={"noreferrer"}  className={styles.itemArrowContainer}>
                        <img src={arrowImg} alt={title} className={styles.itemArrowImg} width={19} height={19} />
                    </a>

                </div>
            </div>
        </div>
    )
}

export default Product;