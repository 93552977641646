import styles from "./style.module.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick.css';
import Star from "./components/Star";
import React from "react";
import {t} from "../../intl/Intl";
import {Testimonial} from "./index.d";

const testimonials: Testimonial[] = [
    {
        id: 1,
        quote: t('testimonialsQuote1'),
        author: t('testimonialsAuthor1'),
        age: 31,
        img: "testimonial/maros.webp"
    },
    {
        id: 2,
        quote: t('testimonialsQuote2'),
        author: t('testimonialsAuthor2'),
        age: 65,
        img: "testimonial/bozena.webp"
    },
    {
        id: 3,
        quote: t('testimonialsQuote3'),
        author: t('testimonialsAuthor3'),
        age: 52,
        img: "testimonial/jaroslav.webp"
    },
    {
        id: 4,
        quote: t('testimonialsQuote5'),
        author: t('testimonialsAuthor5'),
        age: 41,
        img: "testimonial/empty.webp"
    },
    {
        id: 5,
        quote: t('testimonialsQuote4'),
        author: t('testimonialsAuthor4'),
        age: 23,
        img: "testimonial/natalia.webp"
    },
    {
        id: 6,
        quote: t('testimonialsQuote6'),
        author: t('testimonialsAuthor6'),
        age: 34,
        img: "testimonial/igor.webp"
    },
    {
        id: 7,
        quote: t('testimonialsQuote7'),
        author: t('testimonialsAuthor7'),
        age: 30,
        img: "testimonial/eduard.webp"
    },
];

/*
const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    centerPadding: '33%',
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                centerPadding: '0%',
            }
        },
        {
            breakpoint: 768,
            settings: {
                centerPadding: '0',
            }
        }
    ]
};
*/

const Testimonials = () => {

    return (
        <div className={styles.testimonialContainer}>

                {testimonials.map((testimonial) => (
                    <div key={testimonial.id}><div className={styles.testimonialItem}>
                        <div className={styles.testimonialStars}>
                            <Star />
                            <Star />
                            <Star />
                            <Star />
                            <Star />
                        </div>
                        <div className={styles.testimonialQuote}>„{testimonial.quote}“</div>
                        <div className={styles.testimonialAuthor}>
                            <img src={ "/images/" + testimonial.img} alt={testimonial.author} className={styles.testimonialImage} width={50} height={50
                            } fetchpriority={"low"}/>
                            <div className={styles.testimonialAuthorDetails}>
                                <div className={styles.authorName}>{testimonial.author}</div>
                                <div className={styles.authorAge}>{testimonial.age} {t('agePlural')}</div>
                            </div>
                        </div>
                    </div></div>
                ))}

        </div>
    );
}

export default React.memo(Testimonials);