import styles from "./style.module.css";
import {useMemo} from "react";
import {QuestionType} from "./index.d";

const Faq = ({questions = []}: { questions?: QuestionType[] }) => {

    const renderQuestions = useMemo(() => {

        return questions.map((question, index) => {
            return (
                <div key={"q" + index}>
                    <div className={styles.questionContainer} >
                        <div className={styles.orderText}>
                            {index + 1}.
                        </div>
                        <div className={styles.questionText}>
                            {question.question}
                        </div>
                        <div className={styles.answerText}>
                            {question.answer}
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                </div>
            )
        });

    }, [questions]);

    return (
        <div className={styles.container}>
            <div className={styles.separator}></div>
            {renderQuestions}
        </div>
    )
}

export default Faq